<template>
    <CRow>
        <CCol>
           
            <WidgetsDropdownDashboard3/>
            
        </CCol>
     </CRow>
</template>


<script>
import WidgetsDropdownDashboard3 from './widgets/WidgetsDropdownDashboard3';

export default {
  name: 'Dashboard3',
  components: { WidgetsDropdownDashboard3 },
}
</script>
