<template>
  <div id="body">
    <div class="mt-3 ">
      <CRow class="mx-3">
          <img src="@/assets/logo.png" style="height: 60px;" />
          <CCard class="m-auto p-2 ml-4">
            <h2 >{{sited}}</h2>
          </CCard>
      </CRow>
    </div>
    <div class="mt-3 mx-3">  
      <CRow>
        <CCol sm="6" lg="6" >
          <CCard>
            <CCardHeader color="warning">
              On-Going Progress &amp; Estimation
            </CCardHeader>
            <CRow>
              <CCol sm="12" lg="12">
                <CRow>
                  <CCol sm="12" class="mx-2">
                    <CCallout color="info">
                      <small class="text-muted">DC Jatiluhur</small><br>
                      <strong class="h4">{{onprogress.length}}</strong>
                    </CCallout>
                  </CCol>
                </CRow>
                <hr class="mt-0 mx-2">
                <h5 v-if="onprogress.length === 0" class="mx-2">No On-Going Visit.</h5>
                <WidgetsProgressVisit v-for="item in onprogress" :key="item.id"
                  :id="item.id"
                  :company="item.user_id"
                  :activity="item.activity_name"
                  :site="item.site_name"
                  :room="item.room_name"
                  :date = "new Date(item.end_date)"
                  class="mx-2"
                />
              </CCol>
            </CRow>
          </CCard>
        </CCol>
        <CCol sm="6" lg="6">
          <CRow>
            <CCol sm="6" lg="6">
              <CWidgetDropdown color="info" style="font-size:40px" :text="checkin > 1 ? 'Check in Today' : 'Check in Today'">
                <!-- <template #default>
                  <CDropdown
                    color="transparent p-0"
                    placement="bottom-end"
                  >
                    <template #toggler-content>
                      <CIcon name="cil-settings"/>
                    </template>
                    <CDropdownItem>Action</CDropdownItem>
                    <CDropdownItem>Another action</CDropdownItem>
                    <CDropdownItem>Something else here...</CDropdownItem>
                    <CDropdownItem disabled>Disabled action</CDropdownItem>
                  </CDropdown>
                </template> -->
                <template #footer >
                  <CCol style="font-size: 45px">
                    {{checkin}}
                  </CCol>
                </template>
              </CWidgetDropdown>
            </CCol>
            <CCol sm="6" lg="6">
              <CWidgetDropdown color="danger" style="font-size:40px" :text="checkout > 1 ? 'Check out Today' : 'Check out Today'">
                <!-- <template #default>
                  <CDropdown
                    color="transparent p-0"
                    placement="bottom-end"
                  >
                    <template #toggler-content>
                      <CIcon name="cil-settings"/>
                    </template>
                    <CDropdownItem>Action</CDropdownItem>
                    <CDropdownItem>Another action</CDropdownItem>
                    <CDropdownItem>Something else here...</CDropdownItem>
                    <CDropdownItem disabled>Disabled action</CDropdownItem>
                  </CDropdown>
                </template> -->
                <template #footer >
                  <CCol style="font-size: 45px">
                    {{checkout}}
                  </CCol>
                </template>
              </CWidgetDropdown>
            </CCol>
            <CCol sm="6" lg="6">
              <CWidgetDropdown color="warning" style="font-size:40px" :text="onGoing > 1 ? 'Visits On Going' : 'Visit On Going'">
                <!-- <template #default>
                  <CDropdown
                    color="transparent p-0"
                    placement="bottom-end"
                    :caret="false"
                  >
                    <template #toggler-content>
                      <CIcon name="cil-location-pin"/>
                    </template>
                    <CDropdownItem>Action</CDropdownItem>
                    <CDropdownItem>Another action</CDropdownItem>
                    <CDropdownItem>Something else here...</CDropdownItem>
                    <CDropdownItem disabled>Disabled action</CDropdownItem>
                  </CDropdown>
                </template> -->
                <template #footer >
                  <CCol style="font-size: 45px">
                    {{onGoing}}
                  </CCol>
                </template>
              </CWidgetDropdown>
            </CCol>
            <CCol sm="6" lg="6">
              <CWidgetDropdown color="secondary" style="font-size:40px" :text="pending > 1 ? 'Visits Requested' : 'Visit Requested'"
              >
              <!--  <template #default>
                  <CDropdown
                    color="transparent p-0"
                    placement="bottom-end"
                  >
                    <template #toggler-content>
                      <CIcon name="cil-settings"/>
                    </template>
                    <CDropdownItem>Action</CDropdownItem>
                    <CDropdownItem>Another action</CDropdownItem>
                    <CDropdownItem>Something else here...</CDropdownItem>
                    <CDropdownItem disabled>Disabled action</CDropdownItem>
                  </CDropdown>
                </template> -->
                <template #footer >
                  <CCol style="font-size: 45px">
                    {{pending}}
                  </CCol>
                </template>
              </CWidgetDropdown>
            </CCol>
            <CCol sm="6" lg="6">
              <CWidgetDropdown color="dark" style="font-size:40px" :text="cancel > 1 ? 'Visits Cancelled' : 'Visit Cancelled'"
              >
                <!-- <template #default>
                  <CDropdown
                    color="transparent p-0"
                    placement="bottom-end"
                  >
                    <template #toggler-content>
                    <CIcon name="cil-settings"/>
                    </template>
                    <CDropdownItem>Action</CDropdownItem>
                    <CDropdownItem>Another action</CDropdownItem>
                    <CDropdownItem>Something else here...</CDropdownItem>
                    <CDropdownItem disabled>Disabled action</CDropdownItem>
                  </CDropdown>
                </template> -->
                <template #footer >
                  <CCol style="font-size: 45px">
                    {{cancel}}
                  </CCol>
                </template>
              </CWidgetDropdown>
            </CCol>
            <CCol sm="6" lg="6">
              <CWidgetDropdown color="success" style="font-size:40px" :text="complete > 1 ? 'Visits Completed' : 'Visit Completed'"
              >
                <!-- <template #default>
                  <CDropdown
                    color="transparent p-0"
                    placement="bottom-end"
                  >
                    <template #toggler-content>
                    <CIcon name="cil-settings"/>
                    </template>
                    <CDropdownItem>Action</CDropdownItem>
                    <CDropdownItem>Another action</CDropdownItem>
                    <CDropdownItem>Something else here...</CDropdownItem>
                    <CDropdownItem disabled>Disabled action</CDropdownItem>
                  </CDropdown>
                </template> -->
                <template #footer >
                  <CCol style="font-size: 45px">
                    {{complete}}
                  </CCol>
                </template>
              </CWidgetDropdown>
            </CCol>
            <CCol sm="12" lg="12">
              <CCard class="display-1">
                <div class="small text-muted clock" >
                  <CCol>
                    <template >
                      <div class="app d-flex justify-center">
                        <vue-clock  class="m-auto"/>
                      </div>
                    </template>
                  </CCol>
                  <CCol class="text-center font-weight-bold mb-4" style=" font-size : 30px;">
                    {{date(new Date(), 'dddd, Do MMMM YYYY')}}
                  </CCol>
                </div>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http-common';

import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'
import DigitalClock from 'vue-digital-clock'
import moment from 'moment';
import WidgetsProgressVisit from './WidgetsProgressVisitDC';
import TableVisitRequest from '../tables/VisitRequest.vue';
import VueClock from '@dangvanthanh/vue-clock';

export default {
  name: 'WidgetsDropdownDashboard3',
  components: { CChartLineSimple, CChartBarSimple,DigitalClock, WidgetsProgressVisit,TableVisitRequest,VueClock  },
  data () {
		return {
			checkin: '5',
			checkout: '3',
      pending: '7',
      cancel: '9',
      onGoing: '10',
      complete: '1',
      sited: '',
      onprogress: []
    }
  },
  methods:{
    getdata(){
      this.getCheckin()
      this.getCheckout()
      this.getOnGoing()
      this.getRequest()
      this.getCancel()
      this.getCompleted()
      // console.log('testing')
      setInterval(this.getdata, 60000)
      setInterval(this.getVisits, 60000)
    },
    getCheckin(){
      let self = this;
      return http.get('/dc-display/visit-today?site=3&status=check-in')
        .then(function (response) {
            // console.log(response.data.data.count)
            if(response.data.type != 'empty'){
              self.checkin = response.data.data.count.toString()
            }
            // console.log(self.data)
        }).catch(function (error) {
            console.log(error);
        });
    },
    getCheckout(){
      let self = this;
      return http.get('/dc-display/visit-today?site=3&status=check-out')
        .then(function (response) {
            // console.log(response.data.data.count)
            if(response.data.type != 'empty'){
              self.checkout = response.data.data.count.toString()
            }
            // console.log(self.data)
        }).catch(function (error) {
            console.log(error);
        });
    },
    getOnGoing(){
      let self = this;
      return http.get('/dc-display/visit-on-going?site=3')
        .then(function (response) {
            // console.log(response.data.data.count)
            if(response.data.type != 'empty'){
              self.onGoing = response.data.data.count.toString()
              self.sited = response.data.data.site
            }
            // console.log(self.data)
        }).catch(function (error) {
            console.log(error);
        });
    },
    getRequest(){
      let self = this;
      return http.get('/dc-display/visit?status=requested&site=3')
        .then(function (response) {
            // console.log(response.data.data.count)
            if(response.data.type != 'empty'){
              self.pending = response.data.data.count.toString()
            }
            // console.log(self.data)
        }).catch(function (error) {
            console.log(error);
        });
    },
    getCancel(){
      let self = this;
      return http.get('/dc-display/visit?status=canceled&site=3')
        .then(function (response) {
            // console.log(response.data.data.count)
            if(response.data.type != 'empty'){
              self.cancel = response.data.data.count.toString()
            }
            // console.log(self.data)
        }).catch(function (error) {
            console.log(error);
        });
    },
    getCompleted(){
      let self = this;
      return http.get('/dc-display/visit?status=completed&site=3')
        .then(function (response) {
            // console.log(response.data.data.count)
            if(response.data.type != 'empty'){
              self.complete = response.data.data.count.toString()
            }
            // console.log(self.data)
        }).catch(function (error) {
            console.log(error);
        });
    },
    date(value, format) {
			return moment(String(value)).locale('Id').format(format);
		},
    getVisits() {
      let self = this;
      return http.get('/dc-display/visits')
			  .then(function (response) {
          var today = new Date();

          var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

          var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

          var dateTime = date+' '+time;

				  const list = response.data.data;
          let site1 = list.filter(function(item) {
    			  return (item.site_name === 'DC Jatiluhur' && item.status === 'On-Going' );  
				  });
          
          
          if (site1.length > 0) {
            self.onprogress = site1
          }
          
          // console.log(self.dataSets)
			  }).catch(function (error) {
				  console.log(error);
			  });
    }
  },
  mounted () {
    this.getdata();
    this.getVisits();
	},
}
</script>

<style>
.clock {
  border: 10px solid blue;
}
</style>
<style lang="scss">
  // Import custom styles for this application
  @import '@/assets/css/style';
</style>